import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';

const BrandHeaderWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${theme.below.lg} {
    flex-direction: column;
    .brandheaderimage-item {
      display: none;
    }
  }

  > div {
    max-width: 50%;
    ${theme.below.lg} {
      max-width: 100%;
    }
  }

  &.single {
    justify-content: flex-start;
  }
`;

export const BrandHeader = ({ children }) => {
  const items = children?.length;

  return (
    <BrandHeaderWrapper className={items < 2 && 'single'}>
      {children}
    </BrandHeaderWrapper>
  );
};
