import { css } from 'linaria';
import { theme } from '../../Theme';

export const accordionStyle = css`
  position: relative;
  width: 100%;
  padding: 0.5rem 0;

  summary {
    list-style-type: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    ${theme.fonts.desktop.h4}

    ${theme.below.lg} {
      ${theme.fonts.mobile.h4}
    }

    /* REMOVES DEFAULT ARROWS */
    ::-webkit-details-marker {
      display: none;
    }

    .icon-wrapper {
      height: 14px;
      width: 0.8rem;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      transform: rotate(-90deg);
      transition: all 0.3s ease-in-out;

      > * {
        width: 100%;
        height: 100%;
      }
    }
  }
  .accordion-content {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
  }

  &[open] {
    padding-bottom: 1rem;
    .accordion-content {
      opacity: 1;
    }

    summary {
      .icon-wrapper {
        transform: rotate(0deg);
      }
    }
  }
`;
