import React from 'react';
import { default as BreadcrumbsCore } from '@jetshop/ui/Breadcrumbs';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const StyledBreadcrumbs = styled(BreadcrumbsCore)`
  margin-bottom: 8px;
  font-family: ${theme.fonts.primaryFontFamily};
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #282828;

  a {
    text-decoration: none;
  }

  li:last-of-type {
    ::after {
      content: '';
    }
  }
`;

const CustomBreadcrumbs = styled('ul')`
  margin-bottom: 8px;
  font-family: ${theme.fonts.primaryFontFamily};
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  a {
    color: #707070;
    text-decoration: none !important;
    margin-right: 5px;
  }

  li {
    ::after {
      margin-right: 5px;
      content: '/';
    }
  }

  li:last-of-type {
    ::after {
      content: '';
    }
  }
`;

const BreadcrumbsWrapper = styled('div')`
  ul {
    display: flex;
    margin: 0;
    padding: 0;
  }
`;

export const Breadcrumbs = ({
  breadcrumbText,
  breadcrumbs,
  parents,
  customBreadcrumbs
}) => {
  const breadcrumbProps = {
    breadcrumbText,
    breadcrumbs,
    parents
  };
  return (
    <>
      {customBreadcrumbs ? (
        <BreadcrumbsWrapper>
          <CustomBreadcrumbs>
            {customBreadcrumbs?.map((breadcrumb, index) => {
              return (
                <li>
                  <a href={index === 0 ? '/' : '/' + breadcrumb}>
                    {breadcrumb}
                  </a>
                </li>
              );
            })}
          </CustomBreadcrumbs>
        </BreadcrumbsWrapper>
      ) : (
        <StyledBreadcrumbs {...breadcrumbProps} />
      )}
    </>
  );
};
