import { css } from 'linaria';
import { theme } from '../../../Theme';

export const footerContentStyle = css`
  width: 100%;
  border-top: 1px solid ${theme.colors.layout.accentBorder};


  .bottom-footer {
      border-top: 1px solid ${theme.colors.layout.accentBorder};
      ${theme.below.lg} {
        padding: 0 20px;
      }
    }

    .bottom-footer-max-width {
      max-width: 60rem;
      margin: auto;
      height: 90px;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .social-payment {
      display: flex;
      padding-top: 20px;

      svg {
        height: 20px !important;
        width: auto;
      }
    }
    .payment-method-row {
      margin-left: 20px;
      svg {
        height: 14px !important;
      }
    }

    .logo {
      margin: 4px 0;
      align-self: center;
    }

  .max-width {
    flex-direction: column;
    gap: 100px;
    max-width: 60rem;
    padding: 40px 0 20px 0;

    ${theme.below.lg} {

      padding: 40px 20px 0px 20px;
    }

    .top-footer {

      .column-grid {
        display: flex;
        justify-content: space-between;
        ${theme.below.lg} {
          flex-direction: column;
        }
      }
 
      .text-info {
        margin-bottom: 60px;
        ${theme.below.lg} {
          display: none;
   
        }

        svg {
          margin-bottom: 12px;
          height: 50px;
          width: fit-content;
          
        }

        h3 {
          margin: 0 0 9px 0;
          ${theme.fonts.desktop.p1}
          font-weight: ${theme.fontWeights.bold};
          text-transform: uppercase;
          letter-spacing: 0.1em
        }
        p {
          ${theme.fonts.desktop.p1}
          ${theme.below.lg} {
            ${theme.fonts.mobile.p1}
          }
        }
      }
    }

    .full-grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: auto auto;
      grid-column-gap: 100px;
      grid-row-gap: 20px;

      ${theme.below.lg} {
        display: flex;
        flex-direction: column-reverse;
      }

      .text-info {
        grid-area: 1 / 1 / 2 / 3;

        ${theme.below.lg} {
          padding: 60px 0px 20px 0px;
          display: flex;
          flex-direction: column;
          /* align-items: center;
          text-align: center; */
        }

        svg {
          margin-bottom: 12px;
          height: 50px;
          width: fit-content;
          
        }

        h3 {
          margin: 0 0 9px 0;
          ${theme.fonts.desktop.p1}
          font-weight: ${theme.fontWeights.bold};
          text-transform: uppercase;
          letter-spacing: 0.1em
        }
        p {
          ${theme.fonts.desktop.p1}
          ${theme.below.lg} {
            ${theme.fonts.mobile.p1}
          }
        }
      }

      .column-grid {
        grid-area: 1 / 3 / 2 / 6;
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: auto;
        grid-column-gap: 40px;
        grid-row-gap: 0px;
        direction: rtl;
        text-align: left;

        ${theme.below.lg} {
          display: flex;
          flex-direction: column;
        }

        .accordion-details summary {
          flex-direction: row;
        }
      }

    
    }
  }
`;
