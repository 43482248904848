import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';

export const ProductCardVariantSizes = ({ product }) => {
  const options = product?.variants?.values?.map(option => {
    return option?.values?.[0];
  });
  const manySizes = options?.length > 3;
  const alternativeText = t('Many sizes');
  return (
    <>
      {options && options?.length > 0 ? (
        <VariantSizesWrapper className="test">
          {manySizes ? (
            <VariantSizes>{alternativeText}</VariantSizes>
          ) : (
            options?.map(option => (
              <VariantSizes key={option}>{option}</VariantSizes>
            ))
          )}
        </VariantSizesWrapper>
      ) : (
        <VariantSizesWrapper>
          <NoSize>{t('One size')}</NoSize>
        </VariantSizesWrapper>
      )}
    </>
  );
};

const VariantSizesWrapper = styled('div')`
  &.test {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
  }
`;

const VariantSizes = styled('p')`
  margin: 5px 0;
  font-size: 12px;
  text-transform: uppercase;
`;

const NoSize = styled('p')`
  margin: 5px 0;
  font-size: 12px;
  text-transform: uppercase;
  color: transparent;
`;
