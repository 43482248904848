import { css } from 'linaria';

export const productRowStyle = css`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  .swiper-button-prev {
    svg {
      position: absolute;
      left: 20px;
    }
  }

  .swiper-button-next {
    svg {
      position: absolute;
      right: 20px;
    }
  }

  .wrapper {
    width: 100%;
    margin: auto;
  }

  &.fullWidth {
    .wrapper {
      max-width: 100%;
    }
  }
`;
