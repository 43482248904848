import useAuth from '@jetshop/core/components/AuthContext/useAuth';

export const usePriceDisplay = text => {
  const { loggedIn } = useAuth();
  if (!loggedIn) {
    return { showPrice: false };
  }

  return { showPrice: true };
};
