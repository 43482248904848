import React from 'react';
import { cx } from 'linaria';
import { accordionStyle } from './Accordion.style';

// Replace and use what ever standard icon we choose
const Chevron = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 13 7"
    >
      <g transform="translate(24600 -16313)">
        <use
          transform="matrix(0 -1 1 0 -24598.8 16319.2)"
          xlinkHref="#caret_path0_stroke"
        ></use>
      </g>
      <defs>
        <path
          id="caret_path0_stroke"
          d="M0 5.695l-.36-.348-.335.348.335.347.36-.347zM5.137-.347L-.36 5.347l.72.695L5.857.347l-.72-.694zM-.36 6.042l5.497 5.694.72-.694L.36 5.347l-.72.695z"
        ></path>
      </defs>
    </svg>
  );
};

/**
 * The Accordion function is a component that takes title and content and
 * returns them as a Accordion.
 *
 * @param {string} title Accordion title
 * @param {any} content Content to show when accordion is open
 *
 * @return Details containing title and content
 *
 * @docauthor Anders Zetterstrom @ R3
 */
export const Accordion = ({ title, children }) => {
  return (
    <details className={'accordion-details'}>
      <summary>{title}</summary>
      <div className="accordion-content">{children}</div>
    </details>
  );
};

/**
 * The SyledAccordion function is a component that takes title and content and
 * returns them as a styled Accordion.
 *
 * @param {string} title Accordion title
 * @param {any} content Content to show when accordion is open
 *
 * @return Details containing title and content
 *
 * @docauthor Anders Zetterstrom @ R3
 */
export const StyledAccordion = ({ title, children }) => {
  return (
    <details className={cx('accordion-details', accordionStyle)}>
      <summary>
        <div className="icon-wrapper">
          <Chevron />
        </div>
        {title}
      </summary>
      <div className="accordion-content">{children}</div>
    </details>
  );
};
