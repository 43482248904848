import { useContext, useEffect } from 'react';
import { useAddToCart } from '../../../hooks/useAddToCart';
import { QuickBuyContext } from './QuickBuyContext';
import ModalContext from '@jetshop/ui/Modal/ModalContext';
import { useTrackProductAdd } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';

/**
 * The QuickBuyTrigger function is a React component that triggers the QuickBuy.
 * It's used to add products to the cart from within a product card or from a flyout with variants.
 * window.triggerQuickBuy(product, qty) can be used by Third party to trigger a "add to cart"
 *
 * @return Null
 *
 * @docauthor Anders Zetterstrom @ R3
 */
export const QuickBuyTrigger = () => {
  const { showTarget, hideTarget, modals } = useContext(ModalContext);
  const {
    quickBuyProduct,
    setQuickBuyProduct,
    quickBuyQty,
    setQuickBuyQty
  } = useContext(QuickBuyContext);

  const trackAddProductVariant = useTrackProductAdd(quickBuyProduct);

  const trackAddToCart = () => {
    trackAddProductVariant(quickBuyProduct, quickBuyQty);
  };
  const { addProduct } = useAddToCart(trackAddToCart);

  const triggerQuickBuy = (product, qty) => {
    if (product?.variants?.values?.length > 0) {
      setQuickBuyProduct(product);
      setQuickBuyQty(qty);

      const id = 'quick-buy-drawer';
      console.log('Trigger');
      const drawer = {
        showTarget: () =>
          showTarget(null, 'drawer', id, {
            showCover: true,
            canCloseOnEsc: true
          }),
        hideTarget,
        isOpen: modals?.some(({ modalId }) => modalId === id)
      };
      drawer.showTarget();
    } else {
      addProduct(product, qty);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && !window.triggerQuickBuy) {
      window.triggerQuickBuy = triggerQuickBuy;
    }
  }, []);

  return null;
};
