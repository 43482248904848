import { css } from 'linaria';
import { theme } from '../../../Theme';

export const paymentMethodsRowStyle = css`
  /* width: 100%;
  padding: 1rem 0; */

  svg {
    margin-left: 20px;
    ${theme.below.lg} {
      margin-left: 8px;
    }
  }

  .max-width {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;

    svg {
      height: 100%;
      max-height: 40px;
      width: auto;
      max-width: 100px;
    }
  }

  ${theme.below.lg} {
    .max-width {
      gap: 10px;
      svg {
        max-height: 30px;
        max-width: 50px;
      }
    }
  }
`;
