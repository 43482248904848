import React from 'react';
import { SVG } from '../../../../assets/SVG';
import { cx } from 'linaria';
import MaxWidth from '../../MaxWidth';
import { footerContentStyle } from './FooterContent.style';
import { footerLinksColumnStyle } from '../FooterLinksColumn/FooterLinksColumn.style';

export const FooterContent = ({
  backgroundColor,
  foregroundColor,
  showLogo,
  title,
  text,
  children
}) => {
  const filterCEItemsWithKey = (children, key) => {
    const filtered = [];
    const rest = [];
    children?.forEach(child => {
      if (child?.key.startsWith(key)) {
        filtered.push(child);
      } else {
        rest.push(child);
      }
    });

    return { filtered, rest };
  };

  const {
    filtered: paymentMethods,
    rest: restAfterPaymentMethods
  } = filterCEItemsWithKey(children, 'PaymentMethods');

  const { filtered: socialMediaLinks, rest } = filterCEItemsWithKey(
    restAfterPaymentMethods,
    'socialMediaLinks'
  );

  const logo = process.env.REACT_APP_LOGO_NAME;
  const isBorstiq =
    process.env.REACT_APP_LOGO_NAME === 'Borstiq' ? true : false;

  const showTextInfo = title?.value?.length > 0 || text?.value?.length > 11;

  return (
    <div
      className={cx('footer-content', footerContentStyle)}
      style={{
        background: backgroundColor?.value,
        color: foregroundColor?.value
      }}
    >
      <MaxWidth className="max-width">
        <div className="top-footer">
          {showTextInfo && (
            <div className="text-info">
              {title?.value?.length > 0 && <h3>{title?.value}</h3>}
              {text?.value?.length > 0 && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: text?.value
                  }}
                />
              )}
            </div>
          )}
          <div className={cx('column-grid', footerLinksColumnStyle)}>
            {rest}
          </div>
        </div>
      </MaxWidth>
      <div className={cx('bottom-footer', isBorstiq && 'borstiq')}>
        <div className="bottom-footer-max-width">
          <div className="social-payment">
            {socialMediaLinks}
            {paymentMethods}
          </div>
          {showLogo?.value && <SVG className="logo" name={logo + '-alt'} />}
        </div>
      </div>
    </div>
  );
};
