import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import AutocompleteQuery from './AutocompleteQuery.gql';

export const SearchResult = ({ searchValue, children }) => {
  const searchResult = useQuery(AutocompleteQuery, {
    variables: {
      term: searchValue
    }
  });

  return <div>{children(searchResult)}</div>;
};
