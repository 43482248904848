import React from 'react';
import t from '@jetshop/intl';
import { theme } from '../../Theme';
import { Loader } from 'r3-lib/dist/r3lib';
import { css, cx } from 'linaria';

const cartStatusStyle = css`
  padding: 20px 20px;

  &.cart-loading {
    padding: 0;
    margin: 20px;
    height: 20px;
  }
`;

export const CartStatus = ({ result, children }) => {
  if (result?.error) {
    return <CartError />;
  }

  if (result?.loading) {
    return <CartLoading />;
  }

  if (result?.data?.cart?.totalQuantity < 1) {
    return <CartEmpty />;
  }

  return <>{children}</>;
};

const CartEmpty = () => {
  return (
    <h3 className={cx('cart-empty', cartStatusStyle)}>{t('Cart is empty')}</h3>
  );
};

const CartLoading = () => {
  return (
    <div className={cx('cart-loading', cartStatusStyle)}>
      <Loader color={theme.colors.black} />
    </div>
  );
};

const CartError = () => {
  return (
    <h3 className={cx('cart-error', cartStatusStyle)}>
      {t('We are unable to load your cart, please reload the page')}
    </h3>
  );
};
