import React from 'react';
import { SVG } from '../../../../assets/SVG';
import { cx } from 'linaria';
import MaxWidth from '../../MaxWidth';
import { footerContentStyle } from './FooterContent.style';
import { Above } from '@jetshop/ui/Breakpoints';
import { footerLinksColumnStyle } from '../FooterLinksColumn/FooterLinksColumn.style';

export const FooterContentStandard = ({
  backgroundColor,
  foregroundColor,
  showLogo,
  title,
  text,
  children
}) => {
  const filterCEItemsWithKey = (children, key) => {
    const filtered = [];
    const rest = [];

    children?.forEach(child => {
      if (child?.key.startsWith(key)) {
        filtered.push(child);
      } else {
        rest.push(child);
      }
    });

    return { filtered, rest };
  };

  const { filtered: socialMediaLinks, rest } = filterCEItemsWithKey(
    children,
    'socialMediaLinks'
  );

  return (
    <Above breakpoint="lg">
      {matches => (
        <div
          className={cx('footer-content', footerContentStyle)}
          style={{
            background: backgroundColor?.value,
            color: foregroundColor?.value
          }}
        >
          <MaxWidth className="max-width">
            <div className="full-grid">
              <div className="text-info">
                {showLogo?.value && (
                  <SVG name={process?.env?.REACT_APP_LOGO_NAME} />
                )}

                {title?.value?.length > 0 && <h3>{title?.value}</h3>}
                {text?.value?.length > 0 && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: text?.value
                    }}
                  />
                )}
              </div>

              {!matches && <>{socialMediaLinks}</>}

              <div className={cx('column-grid', footerLinksColumnStyle)}>
                {rest}
              </div>

              {matches && <>{socialMediaLinks}</>}
            </div>
          </MaxWidth>
        </div>
      )}
    </Above>
  );
};
