import { css } from 'linaria';

css`
  :global() {
    .box-row-color-item {
      position: relative;
      flex: 1;
    }
  }
`;
