import React from 'react';
import Image from '@jetshop/ui/Image';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../../Theme';
import { VariantSelector } from '../../ProductPage/VariantSelector';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import { useProductValidationMessage } from '../../ProductPage/useProductValidationMessage';
import StockStatusIndicator from '../../ProductPage/StockStatus/StockStatusIndicator';
import { useAddToCart } from '../../../hooks/useAddToCart';
import t from '@jetshop/intl';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import { useTrackProductAdd } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import { CTA } from 'r3-lib/dist/r3lib';
import { QtyAdjuster } from '../../ui/AddToCartQtyButton';
import { StyledPrice } from '../../CategoryPage/ProductCard';
import { useDynamicPrice } from '@jetshop/core/hooks/useDynamicPrice';

const QuickBuyItemWrapper = styled('section')`
  border-bottom: 1px solid ${theme.colors.layout.border};
  margin-bottom: 20px;
  padding-bottom: 20px;

  .size-guide-and-stockstatus {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
  }

  .styled-button-wrapper {
    width: 100%;
  }

  .cta {
    height: 45px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
  }
`;

const ProductInfoRow = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  .image-wrapper {
    flex: 1;
    background: ${theme.colors.lightgrey};

    [data-flight-image-container] {
      mix-blend-mode: darken;
    }
  }

  .product-info {
    margin-left: 10px;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top-row {
      p {
        margin-bottom: 2px;
      }
    }

    .bottom-row {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .qty-adjuster {
        max-width: 100px;

        button,
        input {
          width: calc(100% / 3);
          font-size: 13px !important;
        }
        button {
          height: 32px;

          svg {
            height: 11px;
            width: 11px;
          }
        }
      }

      [data-flight-price] {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: end;
        .price,
        .new-price {
          font-size: 22px;
          line-height: 26px;
          color: black;
          text-align: end;
        }

        .percentage,
        .old-price {
          font-size: 11px;
        }
      }
    }
  }
`;

/**
 * The QuickBuyItem function renders a product in the QuickBuy component.
 *
 * @param {object} quickBuyProduct Pass the product to the quickbuyitem component
 * @param {function} hideTarget Hide the quick buy modal once the user has made a purchase or closes flyout
 * @param {function} setQuickBuyQty Set the quick buy qty from the qtyadjuster
 * @param {number} quickBuyQty Set the initial value of the qtyadjuster
 *
 * @return A component with variants and buy button
 *
 * @docauthor Anders Zetterström @ R3
 */
export const QuickBuyItem = ({
  quickBuyProduct,
  hideTarget,
  setQuickBuyQty,
  quickBuyQty
}) => {
  const variantHandler = useProductVariants(quickBuyProduct, {
    preselectCheapest: true
  });
  const {
    selectedVariant: selectedVariation,
    variantValidation,
    missingOptions
  } = variantHandler;

  console.log('Selected variation', selectedVariation);
  const price = useDynamicPrice(selectedVariation ?? quickBuyProduct);
  const stockStatus = useStockStatus(selectedVariation ?? quickBuyProduct);

  const { hasVariants } = selectedVariation ?? quickBuyProduct;

  const { validationMessage } = useProductValidationMessage({
    missingOptions,
    variantValidation,
    hasVariants,
    stockStatus
  });

  const trackAddProductVariant = useTrackProductAdd(
    selectedVariation ?? quickBuyProduct
  );

  const trackAddToCart = () => {
    trackAddProductVariant(quickBuyProduct, quickBuyQty);
  };
  const { addProduct } = useAddToCart(trackAddToCart);

  const brand = quickBuyProduct?.customFields?.filter(
    customField => customField.key === 'Varumarke'
  );

  return (
    <QuickBuyItemWrapper>
      <ProductInfoRow>
        <div className="image-wrapper">
          <Image
            sizes={[1 / 10]}
            aspect={'1:1'}
            alt={quickBuyProduct?.images?.[0]?.alt}
            src={quickBuyProduct?.images?.[0]?.url}
          />
        </div>
        <div className="product-info">
          <div className="top-row">
            <h4>{brand?.[0]?.stringValue || quickBuyProduct.subName}</h4>
            <p>{quickBuyProduct?.name}</p>
            <p>Art. Nr: {selectedVariation?.articleNumber}</p>
          </div>

          <div className="bottom-row">
            <QtyAdjuster qty={quickBuyQty} setQty={setQuickBuyQty} />
            <StyledPrice
              price={price?.price}
              previousPrice={price?.previousPrice}
            />
          </div>
        </div>
      </ProductInfoRow>
      {quickBuyProduct?.hasVariants && (
        <VariantSelector
          product={quickBuyProduct}
          variantHandler={variantHandler}
          showValidation={!!validationMessage}
          disableOutOfStock
        />
      )}
      <div className="size-guide-and-stockstatus">
        <StockStatusIndicator
          status={stockStatus?.status}
          text={stockStatus?.text}
        />
      </div>

      <CTA
        className={cx('accent', !!validationMessage && 'disabled')}
        clickCallback={() => {
          if (!validationMessage) {
            addProduct(selectedVariation, quickBuyQty);
            hideTarget();
          }
        }}
      >
        <>{validationMessage ? validationMessage : t('Add to cart')}</>
      </CTA>
    </QuickBuyItemWrapper>
  );
};
