import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { LinkEvaluator } from 'r3-lib/dist/r3lib';
import { theme } from '../../Theme';
import { cx } from 'linaria';

const PamphletRowItemWrapper = styled('div')`
  max-width: 20%;
  width: 100%;

  .link-evaluator {
    text-decoration: none;
  }

  .info-wrapper {
    margin-top: 10px;

    &.center {
      text-align: center;
    }
    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 700;
    line-height: 120%; /* 19.93px */
    color: #333;
  }

  p {
    color: #333;
    font-size: 14px;
  }

  ${theme.below.lg} {
    max-width: 100%;
  }
`;

export const PamphletRowItem = ({
  imageUrl,
  title,
  linkText,
  link,
  textPosition
}) => {
  return (
    <PamphletRowItemWrapper>
      <LinkEvaluator link={link?.value}>
        <Image
          src={imageUrl?.value}
          alt={'image'}
          sizes={'450'}
          aspect="90:130"
          cover
        />
        <div className={cx('info-wrapper', textPosition?.value)}>
          <h4 className="title">{title?.value}</h4>
          <p>{linkText?.value}</p>
        </div>
      </LinkEvaluator>
    </PamphletRowItemWrapper>
  );
};
