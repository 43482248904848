import { css } from 'linaria';

export const cartContentStyle = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .cart-text-items,
  .cart-image-items {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }
`;
