import React from 'react';
import Image from '@jetshop/ui/Image/Image';
import { cx } from 'linaria';
import { cartImageItemStyle } from './CartImageItem.style';
export const CartImageItem = ({ image, aspect }) => {
  const aspectRatioAdjustment = ratio => {
    return ratio?.replace(':', '/');
  };
  return (
    <div
      className={cx('cart-image-item', cartImageItemStyle)}
      style={{ aspectRatio: aspectRatioAdjustment(aspect?.value) }}
    >
      <Image
        sizes={[200, 200, 200, 200, 200]}
        alt={'payment method image'}
        src={image?.value?.value}
        fillAvailableSpace={true}
      />
    </div>
  );
};
