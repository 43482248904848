import { addToCartSuccess } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import { useNotification } from '@jetshop/core/components/Notifications';
import { addToCart } from '../components/Cart/queries/addToCart.gql';
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext';
import { useMutation } from '@apollo/react-hooks';
import React, { useContext, useState, useEffect } from 'react';
import ProductToastWrapper from '../components/ProductPage/AddToCart/ProductToast';
import { QuickBuyContext } from '../components/Layout/QuickBuy/QuickBuyContext';

export const useAddToCart = (onComplete = () => {}) => {
  const [product, setProduct] = useState(null);
  const [qty, setQty] = useState(null);
  const { setQuickBuyProduct, setQuickBuyQty } = useContext(QuickBuyContext);

  const { cartId, setCartId } = useContext(CartIdContext);
  const [trigger] = useNotification();

  const [add] = useMutation(addToCart, {
    variables: {
      input: {
        cartId,
        articleNumber: product?.articleNumber,
        quantity: qty
      }
    },
    onCompleted: data => {
      trigger(
        <ProductToastWrapper
          product={product}
          quantity={qty}
          price={{
            price: product?.price,
            previousPrice: product?.previousPrice
          }}
        />,
        { type: 'add-to-cart' }
      );

      onComplete();
      addToCartSuccess({ cartId, setCartId })({ data });
      setProduct(null);
      setQty(null);
      setQuickBuyProduct(null);
      setQuickBuyQty(null);
    }
  });

  useEffect(() => {
    if (product && qty) {
      add();
    }
  }, [product, qty, add]);

  const addProduct = (product, qty) => {
    setProduct(product);
    setQty(qty);
  };

  return { addProduct };
};
