import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import React, { useContext } from 'react';

import { QuickBuyItem } from './QuickBuyItem';
import { ReactComponent as Cross } from '../../../svg/Close.svg';
import { styled } from 'linaria/react';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../../Theme';
import { QuickBuyContext } from './QuickBuyContext';
import { Header } from '../../Cart/CartFlyout';
import { PositionedDrawer } from '../../ui/Flyout/Flyout';

const QuickBuyFlyoutWrapper = styled('div')`
  padding: 0 46px;
  width: 100%;
  height: 100%;

  overflow-y: auto;
  cursor: auto;
  position: relative;

  ${theme.below.lg} {
    padding: 0 25px;
  }

  header {
    margin: 20px 0;
  }

  .closebutton {
    background-color: transparent;
    stroke: ${theme.colors.black};
    stroke-width: 4px;
    stroke-linecap: round;
  }

  /* .heading-3 {
    font-size: 20px;
  } */
`;

/**
 * The QuickBuyFlyout function is a component that handles the drawer
 *
 * @return A drawertarget component that wraps a positioneddrawer component
 *
 * @docauthor Trelent
 */
export const QuickBuyFlyout = () => {
  return (
    <>
      <Above breakpoint="lg">
        {matches => (
          <DrawerTarget id="quick-buy-drawer">
            {drawer => (
              <PositionedDrawer
                isOpen={drawer.isOpen}
                size={matches ? 480 : 332}
                left={false}
              >
                <QuickBuyFlyoutContainer
                  isOpen={drawer.isOpen}
                  hideTarget={drawer.hideTarget}
                />
              </PositionedDrawer>
            )}
          </DrawerTarget>
        )}
      </Above>
    </>
  );
};

/**
 * The QuickBuyFlyoutContainer function renders the QuickBuyFlyout component.
 *
 * @param {bool} isOpen Determine whether the quickbuyflyout is open or closed
 * @param {function} hideTarget Function to hide drawer
 *
 * @return Wrapper with product variants
 *
 * @docauthor Anders Zetterström @ R3
 */
export const QuickBuyFlyoutContainer = ({ isOpen, hideTarget }) => {
  const {
    quickBuyProduct,
    setQuickBuyProduct,
    quickBuyQty,
    setQuickBuyQty
  } = useContext(QuickBuyContext);
  // useEffect(() => {
  //   if (!isOpen) {
  // set default values when hiding drawer
  // setQuickBuyProduct(null);
  // setQuickBuyQty(1);
  //   }
  // }, [isOpen, setQuickBuyProduct, setQuickBuyQty]);

  return (
    <QuickBuyFlyoutWrapper
      onClick={e => {
        e.preventDefault();
      }}
    >
      <Header>
        <button
          onClick={() => {
            hideTarget();
          }}
          className="closebutton"
        >
          <Cross />
        </button>
      </Header>

      {quickBuyProduct && (
        <QuickBuyItem
          quickBuyProduct={quickBuyProduct}
          setQuickBuyProduct={setQuickBuyProduct}
          hideTarget={hideTarget}
          quickBuyQty={quickBuyQty}
          setQuickBuyQty={setQuickBuyQty}
        />
      )}
    </QuickBuyFlyoutWrapper>
  );
};
