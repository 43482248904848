import { styled } from 'linaria/react';
import { theme } from '../../Theme';

export default styled('main')`
  background: #ffffff;
  flex: 1 1 auto;
  /* padding-bottom: 2rem; */
  &:has(.grayBg) {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${theme.colors.layout.secondaryBackground};
  }
`;
