import { css } from 'linaria';

export const headerDropdownStyle = css`
  position: absolute;
  background: white;
  width: 100%;
  height: fit-content;
  max-height: 0px;
  display: flex;
  /* display: flex; */
  /* overflow: scroll; */

  &.open {
    max-height: 80vh;
    /* padding: 24px 24px 44px 24px; */
  }

  .header-dropdown-content {
    position: relative;
  }

  .category-menu {
    padding: 24px 24px 44px 24px;

    &.hidden {
      padding: 0;
    }
  }
`;

export const headerDropdownOverlayStyle = css`
  top: 100%;
  position: fixed;
  left: 0;
  width: 100%;
  height: 100vh;
`;
