// import variables from '../theme-variables.js';
import { createTheme } from '@jetshop/ui/Theme/createTheme';
import eclipse from './theme/eclipse';
import hansbo from './theme/hansbo';
import borstiq from './theme/borstiq';
import themeVariables from '../theme-variables';

// COLLECTING THEME NAME FROM ENVIRONMENT VARIABLE
const themeFileNameKey = process.env.REACT_APP_THEME_KEY || 'default';

// SELECTS THEME FILE BASED ON THEME NAME

const themeSwitcher = themeFileNameKey => {
  switch (themeFileNameKey) {
    case 'eclipse':
      console.log('eclipse');
      return eclipse;

    case 'hansbo':
      console.log('hansbo');
      return hansbo;

    case 'borstiq':
      console.log('borstiq');
      return borstiq;

    default:
      console.log('default');
      return themeVariables;
  }
};

// CREATES THEME
const theme = createTheme(themeSwitcher(themeFileNameKey));

export { theme };
