import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';

const CUSTOMER_QUERY = gql`
  query dynamicContent {
    customer {
      id
      dynamicContent
    }
  }
`;

const AddressFetcher = () => {
  const { loading, error, data } = useQuery(CUSTOMER_QUERY);

  useEffect(() => {
    if (!loading && !error && data) {
      // Add a timestamp to the data
      const timestamp = new Date().getTime();
      const dataWithTimestamp = {
        timestamp,
        ...data.customer
      };
      console.log(dataWithTimestamp);
      // Store the data with timestamp in local storage
      localStorage.setItem('customerData', JSON.stringify(dataWithTimestamp));
    }
  }, [loading, error, data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return <div>{/* Render your component content here */}</div>;
};

export const AddressFetcherControl = () => {
  const { loggedIn } = useAuth();

  if (loggedIn) return <AddressFetcher />;
  return null;
};
