import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { theme } from '../../Theme';

const BrandHeaderTextItemWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 20px;
`;

const ImageWrapper = styled('div')`
  width: 100%;
  max-width: 200px;
`;

const TextWrapper = styled('div')`
  text-align: left;
  width: 100%;
  padding-right: 40px;
  ${theme.below.lg} {
    padding-right: 0;
  }
`;

export const BrandHeaderTextItem = ({ logo, text }) => {
  return (
    <BrandHeaderTextItemWrapper className="brandheader-textitem">
      {logo?.value && (
        <ImageWrapper>
          <Image
            src={logo?.value?.value}
            alt={'logo image'}
            aspect={'1:1'}
            sizes={'450'}
          />
        </ImageWrapper>
      )}
      {text?.value && <TextWrapper>{text?.value}</TextWrapper>}
    </BrandHeaderTextItemWrapper>
  );
};
