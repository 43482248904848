import { css } from 'linaria';
import { theme } from '../../../Theme';

export const footerContentStyle = css`
  width: 100%;
  padding: 6.5rem 0;

  ${theme.below.lg} {
    padding: 12px 0 42px 0;
  }

  .max-width {
    flex-direction: row;
    gap: 100px;

    ${theme.below.lg} {
      flex-direction: column-reverse;
      gap: 55px;
    }

    .full-grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: auto auto;
      grid-column-gap: 100px;
      grid-row-gap: 20px;

      ${theme.below.lg} {
        display: flex;
        flex-direction: column-reverse;
      }

      .text-info {
        grid-area: 1 / 1 / 2 / 3;

        ${theme.below.lg} {
          padding: 60px 0px 20px 0px;
          display: flex;
          flex-direction: column;
          /* align-items: center;
          text-align: center; */
        }

        svg {
          height: 30px;
          width: fit-content;
          margin-bottom: 40px;
        }

        h3 {
          margin: 0 0 9px 0;
          ${theme.fonts.desktop.p1}
          font-weight: ${theme.fontWeights.bold};
          text-transform: uppercase;
          letter-spacing: 0.1em
        }
        p {
          ${theme.fonts.desktop.p1}
          ${theme.below.lg} {
            ${theme.fonts.mobile.p1}
          }
        }
      }

      .column-grid {
        grid-area: 1 / 3 / 2 / 6;
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: auto;
        grid-column-gap: 40px;
        grid-row-gap: 0px;
        direction: rtl;
        text-align: left;

        ${theme.below.lg} {
          display: flex;
          flex-direction: column;
        }

        .accordion-details summary {
          flex-direction: row;
        }
      }

    
    }
  }
`;
