import { css } from 'linaria';

import DMSansRegular from './DMSans-Regular.woff';
import DMSansRegular2 from './DMSans-Regular.woff2';

import DMSansBold from './DMSans-Bold.woff';
import DMSansBold2 from './DMSans-Bold.woff2';

import DMSansSemiBold from './DMSans-SemiBold.woff';
import DMSansSemiBold2 from './DMSans-SemiBold.woff2';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'DM Sans';
        src: local('DMSans Regular'), local('DMSans-Regular'),
          url(${DMSansRegular2}) format('woff2'),
          url(${DMSansRegular}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      /* Bold font */
      @font-face {
        font-family: 'DM Sans';
        src: local('DMSans Bold'), local('DMSans-Bold'),
          url(${DMSansBold2}) format('woff2'), url(${DMSansBold}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'DM Sans';
        src: local('DMSans SemiBold'), local('DMSans-SemiBold'),
          url(${DMSansSemiBold2}) format('woff2'),
          url(${DMSansSemiBold}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }
    }
  `;
}
