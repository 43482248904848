import React from 'react';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { useQuery } from 'react-apollo';
import { cx } from 'linaria';
import CategoryContentQuery from './CategoryContentQuery.gql';
import { allContentComponents } from '../ContentComponents';
import { useStyleApplier } from '../../ui/styles/useStyleApplier';

/**
 * The DynamicCategoryRenderer function is a React component that renders the content of a category.
 * It takes in an array of items and returns them as HTML elements.
 * @param {number} categoryId id for fetching correct category data from the api
 * @param {object} rendererComponents Overrides the default components
 * @param {object} extraRootProps Add extra props to the root component of the category
 * @param {object} extraItemProps} Add extra props to the items
 *
 * @return A div width rendered components
 *
 * @docauthor Anders Zetterström @ R3
 */
export const DynamicCategoryRenderer = ({ categoryId, rendererComponents }) => {
  categoryId === undefined && console.error('categoryId is undefined');
  // console.log('rendererComponents_JonasTestar: ', rendererComponents);
  const { data, error } = useQuery(CategoryContentQuery, {
    variables: {
      id: categoryId
    }
  });
  const items = data?.category?.data?.items;
  useStyleApplier(items);
  if (!data || error) {
    return null;
  }

  const components = rendererComponents ?? allContentComponents;

  let renderItems = items;

  // if (extraRootProps) {
  //   renderItems = insertPropToRootComponents(renderItems, extraRootProps);
  // }

  // if (extraItemProps) {
  //   renderItems = insertPropToComponentsChildren(renderItems, extraItemProps);
  // }

  return (
    <div className={cx('dynamic-category-wrapper')} style={{ width: '100%' }}>
      {items && <ContentRenderer items={renderItems} components={components} />}
    </div>
  );
};
