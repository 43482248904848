import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { styled } from 'linaria/react';
import React, { useEffect, useState } from 'react';
import { cx } from 'linaria';
import { theme } from '../../Theme';

const Scroll = styled('div')`
  flex: 1 1 auto;

  &.grey-bg {
    background: ${theme.colors.lightgrey};
  }
`;

const NewDrawer = styled('nav')`
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100%;
  z-index: 4;
  max-width: 100%;
  ${theme.below.lg} {
    max-width: 90%;
  }
  width: ${props => props.size}px;
  transform: translateX(${props => (props.isOpen ? 0 : props.size)}px);
  transition: transform 0.2s ease-in-out;
  right: 0;

  &.left {
    left: 0;
    right: auto;
    transform: translateX(${props => (props.isOpen ? 0 : -props.size)}px);
  }

  &.right {
    right: 0;
    left: auto;
    transform: translateX(${props => (props.isOpen ? 0 : -props.size)}px);
  }

  &.full {
    max-width: 100%;
    width: 100vw;
    transform: translateX(${props => (props.isOpen ? '0px' : '100vw')});
    &.left {
      transform: translateX(${props => (props.isOpen ? '0px' : '-100vw')});
    }
  }
`;

export const PositionedDrawer = ({
  isOpen,
  size = 350,
  children,
  left,
  full,
  cart
}) => {
  const [topPosition, setTopPosition] = useState('93px'); //useHeaderHeight();
  useEffect(() => {
    if (typeof window !== 'undefined' && window.scrollY > 0) {
      setTopPosition('100px');
    }
  }, [isOpen]);

  return (
    <NewDrawer
      topPosition={topPosition}
      isOpen={isOpen}
      size={size}
      className={cx(left && 'left', full && 'full')}
    >
      <Scroll className={cart && 'grey-bg'}>{children}</Scroll>
    </NewDrawer>
  );
};

export const Flyout = ({
  targetId,
  size,
  left = false,
  full = false,
  cart = false,
  children
}) => {
  return (
    <>
      <DrawerTarget id={targetId}>
        {drawer => {
          return (
            <PositionedDrawer
              isOpen={drawer.isOpen}
              size={size}
              left={left}
              full={full}
              cart={cart}
            >
              {children(drawer)}
            </PositionedDrawer>
          );
        }}
      </DrawerTarget>
    </>
  );
};
