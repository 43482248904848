module.exports = {
  default: {
    breakpoints: {
      xs: '20rem',
      sm: '40rem',
      md: '50rem',
      lg: '64rem',
      xl: '80rem'
    },
    baseColors: {
      black: 'blue',
      white: '#ffffff',
      error: '#DD7476',
      warning: '#ffb347',
      success: '#6fc276'
    },
    colors: {
      //***_Main colors_***
      accent: '#6E8881',
      accentlight: '#6E8881',
      accentTextColor: '#FFFFFF',
      black: '#1C1C1C',
      darkgrey: '#282828',
      grey: '#E9E5DF',
      lightgrey: '#F8F7F5',
      white: '#FFFFFF',
      sales: 'EB0000',

      layout: {
        //***_Layout colors_***
        mainBackground: '#FFFFFF',
        secondaryBackground: '#FFFFFF',
        headerBackground: '#FFFFFF',
        headerColor: '#000000',
        linkColor: '#004E42',
        activeLinkColor: '#006C5B',
        accentBorder: '#E9E5DF',
        border: '#F8F7F5',
        overlay: 'rgba(255, 255, 255, 0.75)',
        productRowArrows: '#CCD6D3'
      },

      //***_Color variants_***
      variant: {
        accent: '#004E42',
        accentTextColor: '#FFFFFF',
        black: '#282828',
        // darkgrey: '#282828',
        // grey: '#878787',
        // lightgrey: '#F8F7F5',
        white: '#D8D8D8'
      }
    },
    fontWeights: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700
    },
    lineHeights: {
      desktop: {
        h1: '122%',
        h2: '88.5%',
        h3: '122%',
        h4: '122%',
        p1: '150%',
        p2: '140%',
        a: '140%'
      },
      mobile: {
        h1: '122%',
        h2: '100%',
        h3: '122%',
        h4: '122%',
        p1: '150%',
        p2: '140%',
        a: '140%'
      }
    },
    fontSizes: {
      desktop: {
        h1: '38px',
        h2: '28px',
        h3: '24px',
        h4: '22px',
        p1: '14px',
        p2: '12px',
        a: '12px'
      },
      mobile: {
        h1: '25px',
        h2: '24px',
        h3: '16px',
        h4: '16px',
        p1: '14px',
        p2: '12px',
        a: '12px'
      }
    },
    fonts: {
      primaryFontFamily: 'DM Sans',
      secondaryFontFamily: 'DM Sans',
      desktop: {
        h1: `
        font-family: DM Sans;
        font-size: 38px;
        line-height: 122%;
        font-weight: 700;
      `,
        h2: `
        font-family: DM Sans;
        font-size: 28px;
        line-height: 122%;
        font-weight: 500;
      `,
        h3: `
        font-family: DM Sans;
        font-size: 24px;
        line-height: 122%;
        font-weight: 700;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      `,
        h4: `
        font-family: DM Sans;
        font-size: 22px;
        line-height: 122%;
        font-weight: 700;
      `,
        p1: `
        font-family: DM Sans;
        font-size: 14px;
        line-height: 150%;
        font-weight: 400;
      `,
        p2: `
        font-family: DM Sans;
        font-size: 12px;
        line-height: 140%;
        font-weight: 400;
      `,
        a: `
        font-family: DM Sans;
        font-size: 12px;
        line-height: 140%;
        font-weight: 700;
        letter-spacing: 0.04em
      `
      },
      mobile: {
        h1: `
        font-family: DM Sans;
        font-size: 25px;
        line-height: 122%;
        font-weight: 700;
      `,
        h2: `
        font-family: DM Sans;
        font-size: 24px;
        line-height: 100%;
        font-weight: 500;
      `,
        h3: `
        font-family: DM Sans;
        font-size: 16px;
        line-height: 122%;
        font-weight: 700;
        letter-spacing: 0.07em;
        text-transform: uppercase;
      `,
        h4: `
        font-family: DM Sans;
        font-size: 14px;
        line-height: 122%;
        font-weight: 700;
      `,
        p1: `
        font-family: DM Sans;
        font-size: 14px;
        line-height: 150%;
        font-weight: 400;
      `,
        p2: `
        font-family: DM Sans;
        font-size: 12px;
        line-height: 140%;
        font-weight: 400;
      `,
        a: `
        font-family: DM Sans;
        font-size: 12px;
        line-height: 140%;
        font-weight: 700;
      `
      }
    },
    layout: {
      headerHeight: {
        desktop: '88px',
        mobile: '62px'
      },
      gaps: {
        none: '0rem',
        xs: '0.25rem',
        sm: '0.5rem',
        md: '1rem',
        lg: '1.5rem',
        xl: '2rem'
      },
      maxWidth: '80rem',
      maxWidthPadding: {
        desktop: '18px',
        mobile: '20px'
      },
      narrowMaxWidth: '50rem',
      productImageAspectRatio: '1:1',
      imageSizes: {
        grid: [300, 300, 300, 300, 300],
        productPage: [1, 1, 1, 300, 600],
        hero: [1, 1, 1, 1, 1],
        rowImage: [1, 1, 1, 300, 600]
      }
    },
    button: {
      desktop: {
        height: '35px',
        padding: '0px 30px',
        borderRadius: '0px'
      },
      mobile: {
        height: '35px',
        padding: '0px 30px',
        borderRadius: '0px'
      },
      buyArea: {
        ctaBorderRadius: '0',
        minusBorderRadius: '0'
      }
    }
  }
};
