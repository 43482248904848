import React from 'react';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import { theme } from '../../../Theme';
import { cx } from 'linaria';
import { LinkEvaluator } from 'r3-lib/dist/r3lib';
import { StyledPrice } from '../../../CategoryPage/ProductCard';
import { searchResultProductCardStyle } from './SearchResultProductCard.style';
import { usePriceDisplay } from '../../../../hooks/usePriceDisplay';
import { useCustomFields } from '../../../ProductPageNew/components/useCustomFields';
import { ReactComponent as MissingImage } from '../../../../svg/image-sharp-light.svg';
import t from '@jetshop/intl';

export const SearchResultProductCard = ({ product, clickCallback }) => {
  const { priceDisplay } = usePriceDisplay();
  const { Varumarke } = useCustomFields(product);
  const imageMissing = !product?.images?.[0]?.url;
  return (
    <div
      className={cx('search-result-product-card', searchResultProductCardStyle)}
    >
      <LinkEvaluator
        linkClickCallback={clickCallback}
        link={product?.primaryRoute?.path}
      >
        {imageMissing ? (
          <div className="missing-image-wrapper">
            <MissingImage className="missing-image" />
            <p className="missing-image-text">{t('No image')}</p>
          </div>
        ) : (
          <Image
            sizes={theme.layout.imageSizes.grid}
            aspect={theme.layout.productImageAspectRatio}
            alt={product?.images?.[0]?.alt}
            src={product?.images?.[0]?.url ?? transparentDataImg}
            fillAvailableSpace={true}
            modifiedDate={product?.images?.[0]?.modifiedDate}
            critical={true}
          />
        )}
        <div className="product-information">
          {Varumarke?.stringValue && <h4>{Varumarke?.stringValue}</h4>}
          <p>{product?.name}</p> {/*slice ???  */}
          {priceDisplay && (
            <StyledPrice
              hidePrice={product?.hidePrice}
              price={product?.price}
              previousPrice={product?.previousPrice}
            />
          )}
        </div>
      </LinkEvaluator>
    </div>
  );
};
