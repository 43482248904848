// This hook is used to apply styles to components based on their type
export const useStyleApplier = items => {
  return items?.forEach(item => {
    componentStyleApplier(item);
  });
};

// Key with component name (lower case) and value with array of styles name (correct ) that needs to be applied
const renderComponentsStyles = {
  hero: ['Hero', 'CTA', 'BoxContent'],
  boxrow: ['BoxRow'],
  boxrowimageitem: ['BoxRowImageItem', 'BoxContent', 'CTA'],
  productrow: ['ProductRow', 'CTA'],
  productrowitem: ['ProductRowItem'],
  onefofeach: ['OneOfEach'],
  boxrowcolor: ['BoxRowColor'],
  boxrowcoloritem: ['BoxRowColorItem', 'BoxContent', 'CTA'],
  spacer: ['spacer'],
  categoryrow: ['CategoryRow', 'CTA'],
  linkrow: ['LinkRow', 'CTA'],
  linkrowitem: ['LinkRowItem'],
  title: ['Title'],
  htmlrow: ['HtmlRow'],
  wysiwyg: ['Html'], // check to add cta style for buttons
  rawhtml: ['Html'], // check to add cta style for buttons
  seorow: ['HtmlRow'],
  cartcontent: ['CartContent'],
  carttextitem: ['CartTextItem'],
  cartimageitem: ['CartImageItem'],
  'footer-1': ['Footer-1'],
  'footer-2-0-column': ['Footer-2-0-Column'],
  'footer-2-1-columnitem': ['Footer-2-1-ColumnItem'],
  paymentmethods: ['PaymentMethods'],
  socialmedialinks: ['SocialMediaLinks'],
  campaignbar: ['CampaignBar'],
  campaignbaritem: ['CampaignBarItem']
};

// Dynamic import of styles (needs to conform to correct naming convention and be in same folder as component)
const styleImporter = styleName => {
  let importedStyle = null;
  try {
    importedStyle = require(`./${styleName}.style.js`).default;
  } catch (error) {
    // console.warn(`🎨 Can't find style file named: ${styleName}.style.js`);
  }

  return importedStyle;
};

// Recursive function that imports styles for component and its children
const componentStyleApplier = component => {
  const stylesNeeded = renderComponentsStyles?.[component?.type?.toLowerCase()];

  stylesNeeded?.map(styleName => {
    styleImporter(styleName);
  });

  component?.children?.forEach(child => {
    componentStyleApplier(child);
  });
};
