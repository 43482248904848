import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';

const BrandHeaderImageItemWrapper = styled('div')`
  width: 100%;
  height: 460px;
`;

export const BrandHeaderImageItem = ({ desktopImage, mobileImage }) => {
  const imageSizes = [1 / 4, 1 / 2, 1 / 2];
  return (
    <BrandHeaderImageItemWrapper className="brandheaderimage-item">
      <Image
        src={desktopImage?.value?.value}
        alt={'image'}
        sizes={imageSizes}
        cover
        fillAvailableSpace
      />
    </BrandHeaderImageItemWrapper>
  );
};
