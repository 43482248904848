import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { Above } from '@jetshop/ui/Breakpoints';

const PamphletRowWrapper = styled('div')`
  display: flex;
  max-width: 80rem;
  width: 100%;
  margin: auto;
  justify-content: flex-start;
  flex-direction: row;

  &.fullWidth {
    max-width: 100%;
  }

  ${theme.below.lg} {
    flex-wrap: wrap;
  }
`;

export const PamphletRow = ({
  children,
  fullWidth,
  desktopPadding,
  mobilePadding,
  gap
}) => {
  return (
    <Above breakpoint="lg">
      {matches => (
        <PamphletRowWrapper
          className={fullWidth?.value && 'fullWidth'}
          style={{
            gap: `${theme?.layout?.gaps?.[gap?.value]}`,
            padding: `0 ${
              matches
                ? desktopPadding?.value + 'px'
                : mobilePadding?.value + 'px'
            }`
          }}
        >
          {children}
        </PamphletRowWrapper>
      )}
    </Above>
  );
};
