import { css } from 'linaria';
import { theme } from '../../../Theme';

export const searchResultProductCardStyle = css`
  margin-bottom: 20px;
  margin-right: 40px;
  .link-evaluator {
    display: flex;
    gap: 20px;
    text-decoration: none;

    [data-flight-image-container] {
      width: 75px !important;
      height: 75px !important;
    }

    .product-information {
      color: ${theme.colors.black};
      h4 {
        ${theme.fonts.desktop.p1}
        line-height: 120%;
        font-weight: ${theme.fontWeights.bold};
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
    }
    .missing-image-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      padding: 3px;
      height: 75px;
      width: 75px;
      .missing-image {
        width: 60%;
        height: 60%;
        fill: grey;
      }
      .missing-image-text {
        font-size: 12px;
      }
    }
  }
`;
