import { css } from 'linaria';
import { theme } from '../../Theme';

export const socialMediaRowStyle = css`
  grid-area: 2 / 5 / 3 / 6;
  ${theme.below.lg} {
    margin-left: 0;
    margin-right: auto;
  }

  h4 {
    color: inherit;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 0.75rem;
  }

  .social-media-row {
    display: flex;
    gap: 1rem;

    a,
    button,
    div {
      background: transparent;
      color: inherit;
    }
    .icon {
      height: 25px;
      width: auto;
      justify-self: center;
    }
  }
`;
