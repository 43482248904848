import React from 'react';
import { cx } from 'linaria';
import { headerDropdowCategoryStyle } from './HeaderDropdownCategory.style';
import { Link } from 'react-router-dom';
import { linkHoverStyle } from '../../../ui/styles/LinkHover.style';
// import { linkHoverStyle } from '../styles/LinkHover.style';

export const HeaderDropdownCategory = ({ category, setOpen, style, close }) => {
  return (
    <div
      className={cx(
        'header-dropdown-category',
        `lvl-${category?.level}`,
        style
      )}
    >
      <Link
        to={category?.primaryRoute?.path}
        onClick={() => {
          setOpen?.(false);
          close();
        }}
        className={style && linkHoverStyle}
      >
        {category?.name}
      </Link>
      {category?.subcategories?.map(subcat => {
        return (
          <HeaderDropdownCategory
            key={subcat?.id + 'lvl' + subcat?.level}
            category={subcat}
            style={style}
            setOpen={setOpen}
            close={close}
          />
        );
      })}
    </div>
  );
};

export const StyledHeaderDropdownCategory = ({ category, setOpen, close }) => {
  return (
    <HeaderDropdownCategory
      category={category}
      setOpen={setOpen}
      style={headerDropdowCategoryStyle}
      close={close}
    />
  );
};
