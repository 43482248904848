import React from 'react';
import { cx } from 'linaria';
import { cartContentStyle } from './CartContent.style';
import { filterChildrenByKey } from 'r3-lib/dist/r3lib';

export const CartContent = ({ children }) => {
  const textChildren = filterChildrenByKey(children, 'cartTextItem', true);
  const imageChildren = filterChildrenByKey(children, 'cartImageItem', true);
  return (
    <div className={cx('cart-content', cartContentStyle)}>
      <div className="cart-text-items">{textChildren}</div>
      <div className="cart-image-items">{imageChildren}</div>
    </div>
  );
};
