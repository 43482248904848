import { css } from 'linaria';
import { theme } from '../../Theme';

css`
  :global() {
    .content-positioner-container {
      .content-pre-title {
        ${theme.fonts.desktop.h4}
        font-size: 18px;
        margin-bottom: 12px;
        letter-spacing: 0.1em;
        ${theme.below.lg} {
          font-size: 16px;
        }
      }

      .content-title {
        ${theme.fonts.desktop.h2};
        margin-bottom: 10px;

        ${theme.below.lg} {
          font-size: 28px;
          line-height: 34px;
        }

        &.hero,
        &.color-box {
          font-size: 43px;
          line-height: 52px;
          ${theme.below.lg} {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }

      .content-text {
        margin-bottom: 10px;
      }

      .cta {
        margin-top: 20px;
        height: ${theme.button.desktop.height};
        padding: ${theme.button.desktop.padding};
      }
    }
  }
`;
