import React from 'react';
import { cx } from 'linaria';
import { searchBarCancelButtonStyle, searchBarStyle } from './SearchBar.style';
import { SVG } from '../../../assets/SVG';

export const SearchBar = ({
  placeholder,
  searchValue,
  setSearchValue,
  keyActions,
  style,
  ...props
}) => {
  return (
    <div className={cx('search-bar', style)}>
      <SVG name={'Magnifier'} />
      <div
        className={cx('search-bar-input', style && searchBarCancelButtonStyle)}
      >
        <input
          type={'search'}
          value={searchValue}
          placeholder={placeholder}
          onChange={e => {
            setSearchValue(e?.target?.value);
          }}
          onKeyDown={e => {
            keyActions?.[e?.key]?.();
          }}
          {...props}
        />
      </div>
    </div>
  );
};

export const StyledSearchBar = ({
  placeholder,
  searchValue,
  setSearchValue,
  keyActions,
  ...props
}) => {
  return (
    <SearchBar
      placeholder={placeholder}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      keyActions={keyActions}
      style={searchBarStyle}
      {...props}
    />
  );
};
