import { css } from 'linaria';

css`
  :global() {
    .box-row-image-item {
      position: relative;
      flex: 1;

      .box-image-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
      }
    }
  }
`;
