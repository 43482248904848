import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { cx } from 'linaria';

const BadgeWrapper = styled('div')`
  position: absolute;
  bottom: -5px;
  right: -5px;
  pointer-events: none;
  height: 17px;
  min-width: 17px;
  width: max-content;
  border-radius: 8px;
  background: ${theme.colors.variant.accent};
  color: ${theme.colors.white};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;

  &.borstiq {
    background: ${theme.colors.accent};
    label {
      color: ${theme.colors.white};
    }
  }

  label {
    color: ${theme.colors.variant.accentTextColor};
    word-break: keep-all;
    font-size: 11px;
    font-weight: 700;
    line-height: 100%;
    /* padding-top: 3px; */
    pointer-events: none;
  }
`;

const Badge = ({ text }) => {
  const isBorstiq =
    process.env.REACT_APP_LOGO_NAME === 'Borstiq' ? true : false;

  if (text === undefined || text < 1) {
    return null;
  }

  return (
    <BadgeWrapper className={cx('cart-badge', isBorstiq && 'borstiq')}>
      <label>{text}</label>
    </BadgeWrapper>
  );
};

export default Badge;
