import { css } from 'linaria';
import { theme } from '../../Theme';

export const barHoverStyle = css`
  position: relative;
  &:after {
    height: 2px;
    width: 0px;
    position: absolute;
    bottom: -8px;
    left: 0;
    background: ${theme.colors.black};
    content: '';
    transition: width 0.15s ease-in-out;
  }
  :hover,
  :active {
    &:after {
      width: 100%;
    }
  }
`;
