import { css } from 'linaria';
import { theme } from '../../../Theme';

export const footerLinksColumnStyle = css`

.footer-links-column {
 display: flex;
  flex-direction: column;
  width: fit-content;
}

 
  h4,
  summary {
    margin-bottom: 0.75rem;
    ${theme.fonts.desktop.p1}
    font-weight: ${theme.fontWeights.bold};
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  a, button, div {
    background: transparent;
    line-height: 160%;
    text-decoration: none;
    color: inherit;
    ${theme.fonts.desktop.p1}
    ${theme.below.lg} {
      ${theme.fonts.mobile.p1}
    }
  }
`;
