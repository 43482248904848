//Library Imports
import {
  LinkRowItem,
  Spacer,
  StyledBoxRow,
  Title,
  StyledHtmlRow,
  StyledHtmlRowItem,
  StyledLinkRow,
  Hero,
  StyledCampaignBar,
  StyledCampaignBarItem,
  BoxRowImageItem,
  BoxRowColorItem
} from 'r3-lib/dist/r3lib';

// //Local Imports
import { StyledProductRow } from './ProductRow';
import { ProductRowItem } from './ProductRowItem';
import { StyledCategoryRow } from './CategoryRow';
import { CartContent } from './CartContent/CartContent';
import { CartTextItem } from './CartTextItem/CartTextItem';
import { CartImageItem } from './CartImageItem/CartImageItem';
import { FooterContent } from '../Layout/Footer/FooterContent/FooterContent';
import { FooterContentStandard } from '../Layout/Footer/FooterContent Standard/FooterContentStandard';
import {
  FooterLinksColumn,
  FooterLinksColumnItem
} from '../Layout/Footer/FooterLinksColumn/FooterLinksColumn';
import { PaymentMethodsRow } from '../Layout/Footer/PaymentMethodsRow/PaymentMethodsRow';
import { SocialMediaRow } from './SocialMediaRow/SocialMediaRow';
import { BrandHeader } from './BrandHeader/BrandHeader';
import { BrandHeaderTextItem } from './BrandHeader/BrandHeaderTextItem';
import { BrandHeaderImageItem } from './BrandHeader/BrandHeaderImageItem';
import { PamphletRow } from './PamphletRow/PamphletRow';
import { PamphletRowItem } from './PamphletRow/PamphletRowItem';

export const allContentComponents = {
  HERO: Hero,
  SPACER: Spacer,
  BOXROW: StyledBoxRow,
  BOXROWIMAGEITEM: BoxRowImageItem,
  TITLE: Title,
  HTMLROW: StyledHtmlRow,
  RAWHTML: StyledHtmlRowItem,
  WYSIWYG: StyledHtmlRowItem,
  PRODUCTROW: StyledProductRow,
  PRODUCTROWITEM: ProductRowItem,
  CATEGORYROW: StyledCategoryRow,
  LINKROW: StyledLinkRow,
  LINKROWITEM: LinkRowItem,
  BOXROWCOLOR: StyledBoxRow,
  BOXROWCOLORITEM: BoxRowColorItem,
  ONEOFEACH: StyledBoxRow,
  BRANDHEADER: BrandHeader,
  BRANDHEADERIMAGEITEM: BrandHeaderImageItem,
  BRANDHEADERTEXTITEM: BrandHeaderTextItem,
  PAMPHLETROW: PamphletRow,
  PAMPHLETROWITEM: PamphletRowItem
};

export const footerComponentsStandard = {
  'FOOTER-1': FooterContentStandard,
  'FOOTER-2-0-COLUMN': FooterLinksColumn,
  'FOOTER-2-1-COLUMNITEM': FooterLinksColumnItem,
  PAYMENTMETHODS: PaymentMethodsRow,
  SOCIALMEDIALINKS: SocialMediaRow
};

export const footerComponentsSpecial = {
  'FOOTER-1': FooterContent,
  'FOOTER-2-0-COLUMN': FooterLinksColumn,
  'FOOTER-2-1-COLUMNITEM': FooterLinksColumnItem,
  PAYMENTMETHODS: PaymentMethodsRow,
  SOCIALMEDIALINKS: SocialMediaRow
};

export const uspComponents = {};

export const seoComponents = {
  SEOROW: StyledHtmlRow,
  RAWHTML: StyledHtmlRowItem,
  WYSIWYG: StyledHtmlRowItem
};

export const cartComponents = {
  CARTCONTENT: CartContent,
  CARTTEXTITEM: CartTextItem,
  CARTIMAGEITEM: CartImageItem
};

export const campaignbarComponents = {
  CAMPAIGNBAR: StyledCampaignBar,
  CAMPAIGNBARITEM: StyledCampaignBarItem
};

export const menuComponents = {
  MENUBOXROW: StyledBoxRow,
  BOXROWIMAGEITEM: BoxRowImageItem
};
