import React, { useState } from 'react';
import { cx } from 'linaria';
import { mobileHeaderStyle } from './MobileHeader.style';

import { LinkEvaluator, useModalManagement } from 'r3-lib/dist/r3lib';
import { useIntl } from '@jetshop/intl';
import { useHistory } from 'react-router-dom';

import { SVG } from '../../../../assets/SVG';
import { StyledSearchBar } from '../../SearchBar/SearchBar';
import { FlyoutTrigger } from '../../../ui/Flyout/FlyoutTrigger';
import { FlyoutMenuLayout } from '../../../ui/Flyout/FlyoutMenuLayout';
import { SearchResult } from '../../SearchResult/SearchResult';
import { FlyoutSearchLayout } from '../../../ui/Flyout/FlyoutSearchLayout';
import { FlyoutCartLayout } from '../../../ui/Flyout/FlyoutCartLayout';
import Badge from '../../../ui/Badge';
import loadable from '@loadable/component';
import LoadingPage from '../../../LoadingPage';
import { campaignbarComponents } from '../../../ContentEditor/ContentComponents';
import { DynamicCategoryRenderer } from '../../../ContentEditor/DynamicCategoryRenderer/DynamicCategoryRenderer';
// import HeaderChannelSelector from '../ChannelSelector/ChannelSelector';

export const LoadableFlyout = loadable(
  () => import('../../../ui/Flyout/Flyout'),
  {
    fallback: <LoadingPage />,
    resolveComponent: Flyout => Flyout.Flyout
  }
);

export const MobileHeader = ({
  categories,
  favouritesCount,
  routes,
  cartData,
  style
}) => {
  const [searchValue, setSearchValue] = useState('');
  const translate = useIntl();
  const history = useHistory();

  const { closeAll } = useModalManagement();
  const { result, cart } = cartData;

  const searchPlaceholderString = translate(
    'Search for product or category...'
  );

  return (
    <div className={cx('mobile-header', style)}>
      <DynamicCategoryRenderer
        categoryId={process?.env?.REACT_APP_CAMPAIGNBAR_CATEGORY_ID}
        rendererComponents={campaignbarComponents}
      />

      <div className={cx('logo-and-icons')}>
        <div className="left">
          <LinkEvaluator
            linkClickCallback={() => {
              closeAll();
            }}
            link={'/'}
          >
            <SVG name="HansboSport" className={'logo'} />
          </LinkEvaluator>
        </div>
        <div className="right">
          {/* <HeaderChannelSelector /> */}
          <LinkEvaluator
            linkClickCallback={() => {
              closeAll();
            }}
            link={'/inkopslista'}
          >
            <div style={{ position: 'relative' }}>
              <SVG name={'Heart'} />
              <Badge text={favouritesCount} />
            </div>
          </LinkEvaluator>
          {/* <LinkEvaluator
            linkClickCallback={() => {
              closeAll();
            }}
            link={routes?.myPages?.path}
          >
            <SVG name={'Person'} />
          </LinkEvaluator> */}
          <FlyoutTrigger triggerId={'header-cart-mobile'}>
            {drawer => (
              <div style={{ position: 'relative' }}>
                <SVG
                  name={'Cart'}
                  className={'cart'}
                  onClick={() => {
                    drawer.isOpen ? drawer.hideTarget() : drawer.showTarget();
                  }}
                />
                <Badge text={cart?.totalQuantity} />
              </div>
            )}
          </FlyoutTrigger>
          <FlyoutTrigger triggerId={'header-menu-mobile'}>
            {drawer => (
              <SVG
                name={'Menu'}
                onClick={() => {
                  drawer.isOpen ? drawer.hideTarget() : drawer.showTarget();
                }}
              />
            )}
          </FlyoutTrigger>
        </div>
      </div>
      <div className="header-search">
        <FlyoutTrigger triggerId={'header-search-mobile'}>
          {drawer => {
            return (
              <StyledSearchBar
                placeholder={searchPlaceholderString}
                searchValue={searchValue}
                setSearchValue={value => {
                  value?.length >= 3
                    ? drawer.showTarget()
                    : drawer.hideTarget();
                  setSearchValue(value);
                }}
                keyActions={{
                  Escape: () => {
                    console.log('ESC button action triggered');
                  },
                  Enter: () => {
                    console.log('ENTER button action triggered');
                    history?.push(
                      `${routes?.search?.path}/?term=${searchValue}`
                    );
                  }
                }}
                onFocus={() => {
                  closeAll();
                }}
              />
            );
          }}
        </FlyoutTrigger>
      </div>

      <LoadableFlyout targetId={'header-menu-mobile'} left={true}>
        {drawer => (
          <FlyoutMenuLayout
            categories={categories}
            active={true}
            parent={null}
            routes={routes}
            close={drawer.hideTarget}
          />
        )}
      </LoadableFlyout>
      <LoadableFlyout targetId={'header-search-mobile'} left={true}>
        {drawer => {
          return drawer.isOpen ? (
            <SearchResult searchValue={searchValue}>
              {searchResult => {
                const autoComplete = searchResult?.data?.searchAutoComplete;

                return (
                  <FlyoutSearchLayout
                    searchValue={searchValue}
                    categories={autoComplete?.categories.result}
                    products={autoComplete?.products?.result}
                    close={() => {
                      drawer.hideTarget();
                      setSearchValue('');
                    }}
                  />
                );
              }}
            </SearchResult>
          ) : null;
        }}
      </LoadableFlyout>
      <LoadableFlyout targetId={'header-cart-mobile'} right={true}>
        {drawer => (
          <>
            <FlyoutCartLayout
              close={drawer.hideTarget}
              result={result}
              cart={cart}
            />
          </>
        )}
      </LoadableFlyout>
    </div>
  );
};

export const StyledMobileHeader = ({
  categories,
  favouritesCount,
  routes,
  cartData
}) => {
  return (
    <MobileHeader
      categories={categories}
      routes={routes}
      cartData={cartData}
      style={mobileHeaderStyle}
      favouritesCount={favouritesCount}
    />
  );
};
