import { css } from 'linaria';
import { theme } from '../../Theme';

css`
  :global() {
    .campaign-bar-item {
      position: relative;
      flex: 1;
      min-height: 30px !important;
      display: flex;
      justify-content: center;
      align-items: center;

      .link-evaluator {
        min-height: 100%;
        display: block;
        text-decoration: none;
        .campaign-bar-item-inner {
          margin-top: 4px;
          ${theme.fonts.desktop.p2}
          text-align: center;
          text-decoration: none;
        }
      }
    }
  }
`;
