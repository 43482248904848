import { css } from 'linaria';
import { theme } from '../../../Theme';

export const cartProductCardStyle = css`
  padding: 20px 0;
  border-top: 1px solid ${theme.colors.layout.border};
  display: flex;
  gap: 20px;
  text-decoration: none;

  .cart-item-image {
    width: 75px;
    height: 75px;
    background: #f8f7f5;
    padding: 5px;
  }

  [data-flight-image-container] {
    width: 65px !important;
    height: 65px !important;
    img {
      mix-blend-mode: multiply;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .product-information {
    justify-content: space-between;
    flex-grow: 1;
    .link-evaluator {
      color: ${theme.colors.black};
      text-decoration: none;
    }

    h4 {
      ${theme.fonts.desktop.p1}
      line-height: 120%;
      font-weight: ${theme.fontWeights.bold};
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    p {
      line-height: 1.2;
      font-size: 14px;
    }
  }

  .cart-product-price {
    white-space: nowrap;
  }

  .remove {
    button {
      background: transparent;
      .svg-wrapper {
        margin: 4px;
        background: ${theme.colors.darkgrey};
        height: 16px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: ${theme.colors.white};
          width: 6px;
        }
      }
    }
  }
`;
