import { styled } from 'linaria/react';
import React from 'react';
import { useIntl } from '@jetshop/intl';
import { useNewsletterSubscription } from '@jetshop/core/hooks/Subscriptions/useNewsletterSubscription';
import { theme } from '../Theme';
import Spinner from '../ui/Spinner';

const FieldErrorWrapper = styled('div')`
  /* background-color: #ffcc00; */
  background-color: ${theme.colors.accent};
  color: ${theme.colors.accentTextColor};
  text-align: center;
  padding-top: 46px;

  h2 {
    text-align: center;
    /* font-family: 'Averta Std'; */
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.7px;
    text-transform: uppercase;
  }
  p {
    text-align: center;
    /* font-family: "Averta Std"; */
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 10px;
  }
`;

const Wrapper = styled('div')`
  max-width: 40rem;
  margin: 20px auto 40px auto;
`;

const InputWrapper = styled('div')`
  background-color: #fff;
  padding: 1px 1px 1px 1px;
  height: 41px;
  width: 511px;
  /* border-radius: 64px; */
  border-radius: ${theme.button.desktop.borderRadius};
  margin: auto;

  ${theme.below.lg} {
    width: 80%;
  }

  form {
    display: flex;
    height: 100%;
  }

  input {
    width: 100%;
    border: none;
    /* border-radius: 32px 0px 0px 32px; */
    border-radius: ${theme.button.desktop.borderRadius};
    padding: 0 14px;
    outline: none;
    color: #000;
  }

  button {
    width: 111px;
    /* border-radius: 32px; */
    border-radius: ${theme.button.desktop.borderRadius};
    background-color: ${theme.colors.accent};
    color: ${theme.colors.accentTextColor};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 136.5%;
    letter-spacing: 0.52px;
    text-transform: uppercase;
  }
`;

const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;

const Error = styled('div')`
  margin-bottom: 0.5rem;
  color: #650e1b;
`;

function NewsletterField() {
  const {
    inputProps,
    submit,
    submitting,
    submitted,
    failed,
    errorStates: { alreadySubscribed, invalidEmail }
  } = useNewsletterSubscription({
    enabled: true
  });

  const t = useIntl();
  const disabled = inputProps.value.length === 0 || submitting;

  return (
    <FieldErrorWrapper>
      <label htmlFor="newsletter">
        <h2>{t('Newsletter')}</h2>
      </label>
      <p>{t('Get news and information first!')}</p>
      <Error>
        {alreadySubscribed &&
          t('You have already subscribed to the newsletter!')}
        {invalidEmail || failed
          ? t('Something went wrong. Please check your email and try again.')
          : null}
      </Error>
      <Wrapper>
        {submitted ? (
          <p data-testid="newsletter-subscription-success">
            {t('You are now subscribed')}
          </p>
        ) : (
          <InputWrapper>
            <form onSubmit={submit} disabled={disabled}>
              <input
                type="email"
                id="newsletter"
                placeholder={t('Enter email address')}
                style={{ paddingRight: submitting ? '8rem' : '4rem' }}
                data-testid="newsletter-subscription-input"
                {...inputProps}
              />
              <button
                type="submit"
                disabled={disabled}
                data-testid="newsletter-subscription-submit"
              >
                {submitting ? <StyledSpinner /> : t('Send')}
              </button>
            </form>
          </InputWrapper>
        )}
      </Wrapper>
    </FieldErrorWrapper>
  );
}

export default NewsletterField;
