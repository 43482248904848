import { useEffect, useState } from 'react';

/**
 * The useBaseFontSize function returns the base font size of the document.
 *
 * @return The font size of the document's body element
 *
 * @docauthor Anders Zetterström @ R3
 */
export const useBaseFontSize = () => {
  const [fontSize, setFontSize] = useState(0);

  useEffect(() => {
    const fontSize = getComputedStyle(document?.documentElement)?.fontSize;
    if (fontSize) {
      const withoutPx = fontSize.replace('px');
      setFontSize(parseInt(withoutPx));
    }
  }, []);

  return fontSize;
};
