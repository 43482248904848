import React from 'react';
import { css, cx } from 'linaria';
import {
  useProductList,
  useProductListItems
} from '@jetshop/core/hooks/ProductList';
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import t from '@jetshop/intl';
import { useNotification } from '@jetshop/core/components/Notifications';
import { ReactComponent as CartIcon } from '../../svg/Cart.svg';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import Button from '../ui/Button';
import { Helmet } from 'react-helmet-async';
import addMultipleToCartMutation from '../Cart/queries/addMultipleToCart.gql';
import cartQuery from '../Cart/queries/cartQuery.gql';
import { ProductCard } from '../CategoryPage/ProductCard';
import { Breadcrumbs } from '../ui/Breadcrumbs';
import { styled } from 'linaria/react';

const container = css`
  margin-top: 2em;
  &.loading {
    opacity: 0.6;
  }

  h1 {
    margin-bottom: 1em;
    font-weight: 600;
  }

  .product-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-right: -10px;
    margin-left: -10px;
    width: 100%;

    > * {
      line-height: 19px;
      min-width: 0;
      width: calc(100% / 5);
    }
  }

  .select-variant {
    margin: 0;
    padding: 1em;
    position: relative;
    z-index: 999;
    [data-flight-dropdown] {
      + [data-flight-dropdown] {
        margin-top: 0.5em;
      }
      button,
      ul,
      li {
        width: 100%;
      }
      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .add-clear button {
    max-width: 30ch;
    display: block;
    margin: 0.5em auto;
  }

  .new-price,
  .old-price {
    display: inline-block;
  }
  .old-price {
    margin-left: 0.5em;
  }
  ${theme.below.lg} {
    padding: 0 5px;
    .product-grid {
      margin-right: 0;
      margin-left: 0;

      > * {
        width: calc(100% / 2);
      }
    }
    .product-link {
      margin: 0 5px 10px !important;
    }
  }
`;

const addAllToastStyles = css`
  background: white;
  color: ${theme.colors.black};
  padding: 1em;
  display: flex;
  align-items: center;

  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    margin-right: 0.5em;
  }
`;

const HeaderWrapper = styled('div')`
  h1 {
    font-family: ${theme.fonts.primaryFontFamily};
    font-weight: 700;
    font-size: 43px;
    line-height: 52px;
    margin: 6px 0 0 !important;
    color: #282828;
  }

  p {
    margin: 10px 0;
  }

  ${theme.below.lg} {
    margin: 0 5px;
    h1 {
      font-size: 28px;
      line-height: 34px;
    }
  }
`;

const ButttonsWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  .add-all {
    min-width: 237px;
    height: 35px;
    color: #ffffff;
  }

  .secondary {
    width: 100%;
    max-width: fit-content !important;
    height: 35px;
    color: #333;
  }

  .add-all,
  .secondary {
    margin: 0 10px 0 0 !important;
    font-weight: 700;
    font-family: ${theme.fonts.primaryFontFamily};
    font-size: 12px;
    line-height: 20px;
  }

  ${theme.below.lg} {
    flex-direction: column;
    .add-all {
      margin-bottom: 10px !important;
    }
  }
`;

export function Favourites() {
  const listId = null;

  const { products, loading } = useProductListItems(listId);
  const [trigger] = useNotification();

  const validItems = getItemsForAddToCart(products);
  const validItemCount = validItems.length;

  const [addToCart, { loading: mutationLoading }] = useAddMultipleToCart(
    validItems,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => {
        trigger(
          <AddAllSuccessToast>
            <CartIcon />
            {t(
              '{productCount, plural, =1 {# product added to the cart.} other {# products added to the cart.}}',
              { productCount: validItemCount }
            )}
          </AddAllSuccessToast>
        );
      }
    }
  );

  const noProductsInList = products.length === 0 && !loading;
  const loadingInitialRender = products.length === 0 && loading;

  if (noProductsInList) {
    return (
      <MaxWidth className={cx(container, loading ? 'loading' : null)}>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <HeaderWrapper>
          <Breadcrumbs customBreadcrumbs={['Home', 'Inköpslista']} />
          <h1>{t('Saved items')}</h1>
          <p>{t('There are no products in your saved items list.')}</p>
        </HeaderWrapper>
      </MaxWidth>
    );
  }

  if (loadingInitialRender) {
    return (
      <MaxWidth className={cx(container, loading ? 'loading' : null)}>
        <HeaderWrapper>
          <Breadcrumbs customBreadcrumbs={['Home', 'Inköpslista']} />
          <h1>{t('Saved items')}</h1>
          <p>{t('Loading your saved items…')}</p>
        </HeaderWrapper>
      </MaxWidth>
    );
  }

  return (
    <MaxWidth className={cx(container, loading ? 'loading' : null)}>
      <HeaderWrapper>
        <Breadcrumbs customBreadcrumbs={['Home', 'Inköpslista']} />
        <h1>Inköpslista</h1>
        <p>Här ser du din inköpslista.</p>
      </HeaderWrapper>
      <ul className="product-grid">
        {products?.map((product, index) => {
          return (
            <li
              key={product?.variant?.articleNumber || product?.articleNumber}
              data-valid={product?.validation?.status}
            >
              <ProductCard
                product={product?.value ?? product}
                favourites={true}
              />
            </li>
          );
        })}
      </ul>
      <ButttonsWrapper className="add-clear">
        <Button
          onClick={addToCart}
          loading={mutationLoading}
          className="add-all"
          disabled={validItemCount === 0}
        >
          {validItemCount > 0
            ? mutationLoading
              ? t('One moment…')
              : t(
                  '{productCount, plural, =1 {Add # product to cart} other {Add # products to cart}}',
                  { productCount: validItemCount }
                )
            : t('No valid items to add to cart')}
        </Button>
        <ClearList listId={listId}>{t('Clear list')}</ClearList>
      </ButttonsWrapper>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </MaxWidth>
  );
}

function AddAllSuccessToast({ children }) {
  return <div className={addAllToastStyles}>{children}</div>;
}

function ClearList({ children, listId }) {
  const { clear } = useProductList(listId);
  return (
    <Button secondary onClick={clear}>
      {children}
    </Button>
  );
}

function getItemsForAddToCart(products) {
  // When adding to cart we only want valid items, and each articleNumber needs
  // to be the variant rather than the base product
  return products
    .filter(product => {
      return product.validation.status === 'valid' && !product.hidePrice;
    })
    ?.map(product => ({
      ...product,
      articleNumber: product.isVariant
        ? product.variant.articleNumber
        : product.articleNumber
    }));
}
