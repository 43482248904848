import React, { useState } from 'react';

const QuickBuyContext = React.createContext();

/**
 * The QuickBuyProvider function is a React context provider that provides the
 * quickBuyProduct and quickBuyQty values to its children.
 *
 * @param {Array} children the children of the component
 *
 * @return A provider with the set functions and values of quickBuyProduct and quickBuyQty
 *
 * @docauthor Anders Zetterström @ R3
 */
const QuickBuyProvider = ({ children }) => {
  const [prod, setProd] = useState(null);
  const [qty, setQty] = useState(1);

  const setQuickBuyProduct = value => {
    setProd(value);
  };

  const setQuickBuyQty = value => {
    setQty(value);
  };

  return (
    <QuickBuyContext.Provider
      value={{
        setQuickBuyProduct,
        quickBuyProduct: prod,
        setQuickBuyQty,
        quickBuyQty: qty
      }}
    >
      {children}
    </QuickBuyContext.Provider>
  );
};

export { QuickBuyProvider, QuickBuyContext };
