import React from 'react';
import { cx } from 'linaria';
import { SVG } from '../../../../assets/SVG';
import { paymentMethodsRowStyle } from './PaymentMethodsRow.style';

export const PaymentMethodsRow = ({
  backgroundColor,
  foregroundColor,
  alignment,
  ...props
}) => {
  const logos = [];

  for (const prop in props) {
    if (props?.[prop]?.type === 'bool' && props?.[prop]?.value) {
      logos?.push(prop?.charAt?.(0)?.toUpperCase?.() + prop?.slice(1));
    }
  }

  const footerVariant = process.env.REACT_APP_FOOTER_VARIANT_ID;

  const alignmentToFlexConverter = alignment => {
    switch (alignment) {
      case 'center':
        return 'center';
      case 'left':
        return 'start';
      case 'right':
        return 'flex-end';
      default:
        return 'center';
    }
  };

  return (
    <div
      className={cx(
        footerVariant === 'standard' ? 'payment-method-row' : '',
        paymentMethodsRowStyle
      )}
      style={{
        background: backgroundColor?.value,
        color: foregroundColor?.value
      }}
    >
      <div
        className={footerVariant === 'standard' ? 'max-width' : ''}
        style={{
          display: footerVariant === 'standard' ? 'flex' : 'block',
          justifyContent: alignmentToFlexConverter(alignment?.value)
        }}
      >
        {logos?.map((logo, index) => {
          return <SVG key={`payment-logo-${index}`} name={logo} />;
        })}
      </div>
    </div>
  );
};
